import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import not_found from "../images/404_h.jpg";

function NotFoundPage() {
  return (
    <Layout>
      <SEO title="404: Not found" />
      <div>
        <img
          className="w-full"
          src={not_found}
        />
      </div>
    </Layout>
  );
}

export default NotFoundPage;
